<template>
  <div style="background-color: white;">
    <van-sticky>

      <van-nav-bar right-text="分享"
                   left-arrow
                   @click-left="onClickLeft"
                   @click-right="onClickShare">
        <!-- <template #right>
          <van-icon name="share-o"
                    size="18"
                    @click="onClickShare" />
        </template> -->
        <template #title>
          <span style="font-weight:600;color:#0af">Go视界</span>
        </template>
        <!-- <template #left>
          <van-image radius="18px"
                     height="40px"
                     width="40px"
                     @click="txClick"
                     src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg" />
        </template> -->
      </van-nav-bar>

      <van-cell size="large">

        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <span class="custom-title">{{videoDetail.videoDesc}}</span>
        </template>

        <template #label>

          <van-tag style="margin-left:5px"
                   plain
                   size="medium"
                   type="primary">🙅 {{videoDetail.author}}</van-tag>
          <van-tag style="margin-left:10px"
                   plain
                   size="medium"
                   type="warning">
            🕜 {{videoDetail.createTime}}</van-tag>
        </template>
      </van-cell>
    </van-sticky>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">

    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <iframe :src="videoDetail.videoUrl"
                width="100%"
                height="220"
                scrolling="no"
                border="0"
                frameborder="no"
                framespacing="0"
                allowfullscreen="true"> </iframe>

      </van-col>
    </van-row>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      视频文档
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <div v-if="videoDetail.content !== null && videoDetail.content !== ''"
             class="markdown-body">
          <viewer ref="markdownViewer"
                  :options="viewerOptions"
                  :height="'auto'"
                  :initialValue="videoDetail.content"></viewer>
        </div>
        <div v-else>
          <van-empty description="文档整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center">
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>

    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />
  </div>
</template>

<script>
import { ImagePreview, Image as VanImage, NavBar, Dialog, Toast, ShareSheet, Sticky, Divider, Empty, Cell, Tag, Col, Row, Icon } from 'vant';
import Valine from '../Valine.vue';
const axios = require("axios");
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import '../css/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

let hasPath;
export default {

  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [VanImage.name]: VanImage,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    [Dialog.Component.name]: Dialog.Component,
    viewer: Viewer,
    [Sticky.name]: Sticky,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    Valine,
    [Empty.name]: Empty,

    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,

  },

  data () {
    return {
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'https://img01.yzcdn.cn/vant/custom-icon-light.png' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      viewerOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },
      id: "",
      videoDetail: {

      },
      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },
    };
  },
  //用于判断跳转回主页还是上一页
  beforeRouteEnter (to, from, next) {
    hasPath = from.path;
    // console.log(hasPath);  // 返回: ' / ' 
    next()
  },
  created () {
    this.id = this.$route.query.id
    //设置邀请码
    if (this.$route.query.code !== undefined) {
      window.localStorage.setItem('code', this.$route.query.code)
    }
    this.initData()
  },
  methods: {
    initData () {
      axios
        .get("https://api.golangroadmap.com/video/m/detail?id=" + this.id)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.videoDetail = response.data.data
            this.valineOptions.path = '/video/' + this.videoDetail.id + '.html'
          } else {
            console.log(response.data.msg);
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //点击头像进入服务页
    txClick () {
      // this.$router.push('home');
      this.$router.push({ path: '/home', query: { tab: '1' } });
    },
    onClickLeft () {

      if (hasPath == '/') {
        this.$router.push({ path: '/home', query: { tab: '1' } });   // 这里我返回到home首页
        return;
      }
      this.$router.go(-1);

    },
    //分享
    onSelect (shareOption) {

      if (shareOption.name == '分享海报') {
        var shareImage = 'https://image-1302243118.cos.ap-beijing.myqcloud.com/share/video_' + this.videoDetail.id + '.jpg'
        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [
              shareImage,
            ],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({ message: '暂无海报,请选择微信分享', theme: 'round-button', confirmButtonColor: '#0af' });
        };

      } else {
        Dialog({ message: '本文链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
        this.showShare = false;
        let container = this.$refs.container
        var invitationCode = window.localStorage.getItem('invitationCode') == null ? 'Go' : window.localStorage.getItem('invitationCode')

        var shareUrl = this.videoDetail.videoDesc + ": https://m.golangroadmap.com/#/sharevideo?id=" + this.videoDetail.id + "&code=" + invitationCode + "。GOLANG ROADMAP一个专注Go语言学习、求职的社区。"
        this.$copyText(shareUrl, container)
      }
    },
    //打开分享
    onClickShare () {
      this.showShare = true
    },
  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
